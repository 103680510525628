export const trainersImages = [
  {
    title: "Marketing Launchpad",
    name: "Digital",
    description: "19 Weeks | Full-Time",
    link:
      "https://www.kraftshala.com/marketing-launchpad/digital-marketing-course/",
  },

  {
    title: "Marketing Launchpad ",
    name: "Digital",
    description: "8 Months | Part-Time",
    link:
      "https://www.kraftshala.com/marketing-launchpad/part-time-digital-marketing-course/",
  },
  {
    title: "Business Leadership",
    name: "PGP in Sales and",
    subTitle: "",
    mobSubTitle: "PGP in Sales and Business Leadership",
    description: "9 months | Full-Time",
    link:
      "https://www.kraftshala.com/sales-course/",
  },
  {
    title: "Launchpad - Emerging Talent",
    name: "Digital  Marketing",
    subTitle: "(Hindi & English)",
    description: "19 Weeks | Full-Time",
    link:
      "https://www.kraftshala.com/marketing-launchpad/digital-marketing-course-in-hindi/",
  },
];
